import request from '@/plugins/axios'

export function facebookList(data) {
  return request({
    url: `/facebook/users`,
    method: 'get',
    params: data
  })
}

export function articlesList(data) {
  return request({
    url: `/facebook/articles`,
    method: 'get',
    params: data
  })
}

// 落查 查询记录
export function logTask(data) {
  return request({
    url: `/investigation/tasks`,
    method: 'get',
    params: data
  })
}
//任务提交
export function setTask(data) {
  return request({
    url: `/investigation/tasks`,
    method: 'post',
    data
  })
}
// 根据id获取任务详情
export function logDetail(id) {
  return request({
    url: `/investigation/tasks/${id}`,
    method: 'get'
  })
}
// 获取落查次数变更记录列表

export function countChangeLogs(data) {
  return request({
    url: `/investigation/countChangeLogs`,
    method: 'get',
    params: data
  })
}
