import request from '@/plugins/axios'

export function addScheme(data) {
  return request({
    url: `/scheme/collections`,
    method: 'post',
    data
  })
}
export function getScheme(data) {
  return request({
    url: `/scheme/collections`,
    method: 'get',
    params: data
  })
}
export function delScheme(id) {
  return request({
    url: `/scheme/collections/${id}`,
    method: 'delete'
  })
}
export function getArticles(collection_id, data) {
  return request({
    url: `/scheme/collections/${collection_id}/articles`,
    method: 'get',
    params: data
  })
}

export function updateScheme(id, data) {
  return request({
    url: `/scheme/collections/${id}`,
    method: 'PATCH',
    data
  })
}
export function delTask(parent, id) {
  return request({
    url: `/scheme/collections/${parent}/tasks/${id}`,
    method: 'delete'
  })
}
export function setTask(collection_id, data) {
  return request({
    url: `/scheme/collections/${collection_id}/tasks`,
    method: 'post',
    data
  })
}
