<template>
  <div class="multivariate-index">
    <div class="multivariate-head">
      <div class="head-item" v-for="(item, i) in headArr" :key="i">
        <img :src="item.img" alt="" />
        <div class="text-box">
          <div v-if="!item.bold" class="item-text">
            {{ item.memo1 }}
          </div>
          <div v-else class="item-text">
            <span>{{ item.memo1 }}</span>
            {{ item.measurement }}
          </div>
          <div class="item-text">
            {{ item.memo2 }}
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="multivariate-content">
      <div>
        <div class="index-title">
          <div>用户方案</div>
          <span @click="goPath('/management/user')">更多>></span>
        </div>
        <el-table
          :data="communityList"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          height="330px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '6px 0' }"
        >
          <el-table-column
            prop="name"
            label="方案名"
            show-overflow-tooltip
          ></el-table-column>

          <el-table-column prop="create_time" label="创建时间">
            <template slot-scope="scope">
              {{ scope.row.create_time | capitalize }}
            </template>
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
      </div>
      <div>
        <div class="index-title">
          <div>关键词方案</div>
          <span @click="goPath('/management/keywords')">更多>></span>
        </div>
        <el-table
          :data="communityArr"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          height="330px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '6px 0' }"
        >
          <el-table-column
            prop="name"
            label="方案名"
            show-overflow-tooltip
          ></el-table-column>

          <el-table-column prop="create_time" label="创建时间">
            <template slot-scope="scope">
              {{ scope.row.create_time | capitalize }}
            </template>
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
      </div>

      <div>
        <div class="index-title">
          <div>facebook用户检索</div>
          <span @click="goPath('/data/user')">更多>></span>
        </div>
        <el-table
          :data="accountList"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          height="330px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '6px 0' }"
        >
          <el-table-column
            prop="nick_name"
            label="用户名"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column prop="create_time" label="创建时间">
            <template slot-scope="scope">
              {{ scope.row.create_time | capitalize }}
            </template>
          </el-table-column>
          <el-table-column
            prop="user_name"
            label="用户个性域名"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
      </div>
      <!-- -->
    </div>
    <div class="multivariate-foot">
      <div>
        <div class="index-title">
          <div>近7天检索记录</div>
        </div>
        <div id="myChrt"></div>
      </div>
      <div>
        <div class="index-title">
          <div>次数使用记录</div>
          <!-- <span @click="goPath('/key/channel')">更多>></span> -->
        </div>
        <el-table
          :data="userList"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          :height="windowsHeight - 720"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
        >
          <el-table-column prop="create_time" label="时间">
            <template slot-scope="scope">
              {{ scope.row.create_time | capitalize }}
            </template>
          </el-table-column>
          <el-table-column
            prop="reason"
            label="类型"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="change_value"
            label="积分变更"
          ></el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
        </el-table>
        <pagination
          class="video-pagination-child"
          :total="total"
          :page-size="limit"
          :currentPage="current"
          :pagerCount="5"
          background
          :sizesArr="[10, 50, 100]"
          @currentChange="currentChange"
          @sizeChange="sizeChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { statistics, collectionAmount } from '@/api/dashboard.js'
import { getScheme } from '@/api/management.js'
import { facebookList, countChangeLogs } from '@/api/data.js'
export default {
  data() {
    return {
      noPic: 'this.src="' + require('@/assets/imgs/i12.png') + '"',
      headArr: [
        {
          img: require('../../assets/imgs/t1.png'),
          memo1: '开通日期：2020-03-01',
          // memo2: '结束日期：2020-03-01',
          bold: false
        },
        {
          img: require('../../assets/imgs/f1.png'),
          memo1: 0,
          memo2: '用户方案',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/f2.png'),
          memo1: 0,
          memo2: '关键词方案',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/f3.png'),
          memo1: 0,
          memo2: '用户数据',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/f4.png'),
          memo1: '0',
          memo2: '帖子数据',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/f5.png'),
          memo1: '0',
          memo2: '已落查次数',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/f6.png'),
          memo1: '0',
          memo2: '剩余落查次数',
          bold: true,
          measurement: ''
        }
      ],
      communityList: [],
      communityArr: [],
      accountList: [],
      userList: [],
      // 当前页
      current: 1,
      // 每页记录数
      limit: 10,
      // 总记录数
      total: 0
    }
  },
  filters: {
    capitalize: function(value) {
      if (!value) return ''
      let arr = value.split('+')
      if (Array.isArray(arr)) {
        return arr[0]
      } else {
        return value
      }
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    },
    info() {
      return this.$store.state.user.userInfo
    }
  },

  mounted() {
    // 获取头部6个数据
    this.getHead()

    this.headArr[0].memo1 =
      '开通日期：' + this.info.date_joined.substring(0, 10)
    // this.headArr[0].memo2 = '结束日期：' + this.info.endDate

    this.getCollectionAmount()

    // 用户方案数据
    this.getPage(2).then(res => {
      this.communityList = res.data.data
    })
    // 关键词方案数据
    this.getPage(1).then(res => {
      this.communityArr = res.data.data
    })
    // 用户检索
    this.getFacebookList()
    // 变更记录
    this.getcountChangeLogs()
  },
  methods: {
    getCollectionAmount() {
      collectionAmount().then(res => {
        if (res.status == 200) {
          this.getEchartsBar('myChrt', res.data.x_axis, res.data.y_axis)
        }
      })
    },
    goPath(path) {
      this.$router.push(path)
    },

    getHead() {
      statistics().then(res => {
        console.log(res)
        if (res.status == 200) {
          this.headArr[1].memo1 = res.data.user_collection_count
          this.headArr[2].memo1 = res.data.keyword_collection_count
          this.headArr[3].memo1 = res.data.user_count
          this.headArr[4].memo1 = res.data.article_count
          this.headArr[5].memo1 = res.data.investigation_task_count
          this.headArr[6].memo1 = res.data.investigation_unused_count
        }
      })
    },

    getEchartsBar(dom, dataX, dataY) {
      let chart = echarts.init(document.getElementById(dom))

      let option = {
        grid: {
          x: 50,
          y: 20,
          x2: 30,
          y2: 20,
          borderWidth: 10
        },
        xAxis: {
          type: 'category',
          data: dataX,
          splitLine: { show: true }
        },
        yAxis: {
          type: 'value',
          splitLine: { show: true }
        },
        tooltip: {
          //提示框组件
          trigger: 'item', //触发类型,'item'数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用。 'axis'坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用。
          triggerOn: 'mousemove', //提示框触发的条件,'mousemove'鼠标移动时触发。'click'鼠标点击时触发。'mousemove|click'同时鼠标移动和点击时触发。'none'不在 'mousemove' 或 'click' 时触发
          showContent: true, //是否显示提示框浮层
          alwaysShowContent: false, //是否永远显示提示框内容
          showDelay: 0, //浮层显示的延迟，单位为 ms
          hideDelay: 100, //浮层隐藏的延迟，单位为 ms
          enterable: false, //鼠标是否可进入提示框浮层中
          confine: false, //是否将 tooltip 框限制在图表的区域内
          transitionDuration: 0.4, //提示框浮层的移动动画过渡时间，单位是 s,设置为 0 的时候会紧跟着鼠标移动
          // position: ["50%", "50%"], //提示框浮层的位置，默认不设置时位置会跟随鼠标的位置,[10, 10],回掉函数，inside鼠标所在图形的内部中心位置，top、left、bottom、right鼠标所在图形上侧，左侧，下侧，右侧，
          // formatter: "{b0}: {c0}<br />{b1}: {c1}", //提示框浮层内容格式器，支持字符串模板和回调函数两种形式,模板变量有 {a}, {b}，{c}，{d}，{e}，分别表示系列名，数据名，数据值等
          backgroundColor: 'transparent', //标题背景色
          borderColor: '#ccc', //边框颜色
          borderWidth: 0, //边框线宽
          padding: 5 //图例内边距，单位px  5  [5, 10]  [5,10,5,10]
        },
        series: [
          {
            data: dataY,
            type: 'bar',
            barWidth: 21,
            showBackground: false,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            itemStyle: {
              borderRadius: [10, 10, 10, 10],
              color: new echarts.graphic.LinearGradient(
                0,
                1,
                0,
                0,
                [
                  {
                    offset: 0,
                    color: '#48C6EF'
                  },
                  {
                    offset: 1,
                    color: '#6F86D6'
                  }
                ],
                false
              )
            }
          }
        ]
      }
      window.onresize = function() {
        chart.resize()
      }
      chart.setOption(option)
    },
    currentChange(val) {
      this.current = val
      this.getcountChangeLogs()
    },
    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getcountChangeLogs()
    },
    // 获取方案列表
    getPage(type) {
      return new Promise((resolve, reject) => {
        let obj = {
          page: 1,
          page_size: 8,
          collection_type: type
        }
        getScheme(obj).then(res => {
          if (res) {
            resolve(res)
          }
        })
      })
    },
    // 用户数据
    getFacebookList() {
      let obj = {
        search: '',
        page: 1,
        page_size: 8
      }
      facebookList(obj).then(res => {
        this.accountList = res.data.data
      })
    },
    //使用记录
    getcountChangeLogs() {
      let obj = {
        page: this.current,
        page_size: this.limit
      }
      countChangeLogs(obj).then(res => {
        this.userList = res.data.data
        this.total = res.data.count
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.multivariate-index {
  width: 100%;
  height: calc(100vh - 50px);
  padding: 10px 20px;
  background: rgb(240, 242, 245);

  .multivariate-head {
    width: 100%;
    height: 141px;
    background: white;
    border-radius: 5px;
    display: flex;
    box-shadow: 0 1px 3px 0 #d8d9d9;
    .head-item {
      width: calc(100% / 7 - 1px);
      border-right: 1px solid #f2f4f8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      &:nth-last-child(1) {
        border-right: none;
      }
      img {
        height: auto;
        object-fit: cover;
        align-self: center;
      }
      .text-box {
        align-self: center;
        margin-top: 8px;
        text-align: center;
        .item-text {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span {
            font-size: 22px;
          }
        }
      }
    }
  }
  .multivariate-content {
    height: 389px;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    > div {
      width: 33%;
      height: 100%;
      background: #ffffff;
      border-radius: 5px;
      padding: 5px;
      overflow: hidden;
      box-shadow: 0 1px 3px 0 #d8d9d9;
    }
  }
  .multivariate-foot {
    width: 100%;
    height: calc(100% - 550px);
    display: flex;
    justify-content: space-between;
    > div {
      width: calc(50% - 5px);
      height: 100%;
      background: white;
      border-radius: 5px;
      overflow: hidden;
      padding: 5px;
      box-shadow: 0 1px 3px 0 #d8d9d9;
    }
    #myChrt {
      width: 100%;
      height: calc(100% - 40px);
    }
  }

  .index-title {
    display: flex;
    justify-content: space-between;
    text-indent: 9px;
    font-size: 14px;
    font-weight: 400;
    color: #4a4a4a;
    line-height: 24px;
    border-bottom: 1px solid #f4f4f4;
    padding: 5px 0;
    font-weight: 600;
    span {
      color: #7c7b7b;
      cursor: pointer;
      &:hover {
        color: rgb(11, 106, 214);
      }
    }
  }
  // ::v-deep .el-table__header .el-table__cell {
  //   background: rgb(243, 247, 249);
  // }
  ::v-deep .tableheader th {
    background: rgb(243, 247, 249);
    padding: 0;
    height: 35px;
  }
  .table-cell-img {
    width: 28px;
    height: 28px;
    object-fit: cover;
    vertical-align: middle;
  }
}
</style>
